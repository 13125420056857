var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.questionVoList && _vm.questionVoList.length)?_c('div',{staticClass:"content-div"},[_c('div',{staticClass:"btn-div"},[_c('Button',{attrs:{"type":"primary"},on:{"click":_vm.addBtn}},[_vm._v("添加下一组")])],1),_vm._l((_vm.questionVoList),function(item,index){return _c('div',{key:index,staticClass:"item"},[_c('div',{staticClass:"detele-btn",staticStyle:{"display":"flex"}},[(_vm.questionVoList.length >= 2)?_c('Button',{staticStyle:{"margin-left":"auto"},attrs:{"type":"error","icon":"ios-trash"},on:{"click":function($event){return _vm.deleteBtn(index)}}},[_vm._v("删除当前组")]):_vm._e()],1),_c('div',{staticClass:"item-div"},[_c('div',{staticClass:"left"},[_c('div',{staticClass:"text"},[_vm._v("内容设置")]),_c('div',{staticClass:"form-content"},[_c('Form',{ref:"form",refInFor:true,staticClass:"form",attrs:{"model":item,"label-position":"right","label-width":120,"rules":_vm.itemValidateForm}},[_c('FormItem',{attrs:{"label":"排序","prop":"topicName"}},[_c('Input',{attrs:{"type":"Number","placeholder":"填写排序"},model:{value:(item.seq),callback:function ($$v) {_vm.$set(item, "seq", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"item.seq"}})],1),_c('FormItem',[_c('span',{attrs:{"slot":"label"},slot:"label"},[_vm._v("背景素材:")]),_c('RadioGroup',{on:{"on-change":function (val) {
                                        _vm.changeExtraType(val, index)
                                    }},model:{value:(item.extraType),callback:function ($$v) {_vm.$set(item, "extraType", $$v)},expression:"item.extraType"}},[_c('Radio',{attrs:{"label":"1"}},[_vm._v("图片")]),_c('Radio',{attrs:{"label":"2"}},[_vm._v("视频")])],1)],1),(item.extraType && item.extraType == '1')?_c('FormItem',[(item.extraType == '1' && _vm.showImage)?_c('LiefengUpload',{ref:"imgData",refInFor:true,attrs:{"accept":".jpg,.png,.gif,.jpeg","format":['jpg', 'png', 'jpeg', 'gif'],"defaultList":item.extra,"showView":true,"target":'image'},on:{"success":function (target, val) {
                                        _vm.uploadSuccess(target, val, index)
                                    }}}):_vm._e()],1):_vm._e(),(item.extraType && item.extraType == '2')?_c('FormItem',[(item.extraType == '2')?_c('LiefengUploadVideo',{ref:"videoData",refInFor:true,attrs:{"defaultList":item.extra,"target":'video',"showView":true},on:{"success":function (target, val) {
                                        _vm.uploadSuccess(target, val, index)
                                    }}}):_vm._e()],1):_vm._e(),_c('FormItem',{attrs:{"prop":"topicDesc"}},[_c('span',{staticClass:"validate",attrs:{"slot":"label"},slot:"label"},[_vm._v("内容描述")]),_c('Input',{attrs:{"type":"textarea","rows":10,"placeholder":"内容描述，必填","maxlength":"500"},model:{value:(item.questionDesc),callback:function ($$v) {_vm.$set(item, "questionDesc", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"item.questionDesc"}})],1),_c('FormItem',{attrs:{"prop":"topicDesc"}},[_c('span',{attrs:{"slot":"label"},slot:"label"},[_vm._v("语音文件")]),(_vm.showAudio)?_c('LiefengUploadAudio',{ref:("LiefengUploadAudio" + index),refInFor:true,attrs:{"defaultFileList":item.voiceFile},on:{"success":function (val) {
                                        _vm.uploadAudio(val, index)
                                    }}}):_vm._e()],1)],1)],1)]),_c('div',{staticClass:"right"},[_c('div',{staticClass:"text"},[_c('div',[_vm._v("问题设置")]),_c('div',{staticClass:"right-btn"},[_c('Button',{attrs:{"type":"primary"},on:{"click":function($event){return _vm.addPromlem(index)}}},[_vm._v("添加多个问题")])],1)]),_vm._l((item.answerVoList),function(items,indexs){return _c('div',{key:indexs,staticClass:"form-content"},[_c('div',{staticClass:"right-form"},[_c('Form',{attrs:{"label-position":"right","model":items,"label-width":120}},[_c('FormItem',[_c('span',{staticClass:"validate",attrs:{"slot":"label"},slot:"label"},[_vm._v("题目类型:")]),_c('RadioGroup',{model:{value:(items.questionType),callback:function ($$v) {_vm.$set(items, "questionType", $$v)},expression:"items.questionType"}},[_c('Radio',{attrs:{"label":"1"}},[_vm._v("选择题")]),_c('Radio',{attrs:{"label":"2"}},[_vm._v("打分题")])],1)],1),(items.questionType == 1)?_c('FormItem',[_c('span',{staticClass:"validate",attrs:{"slot":"label"},slot:"label"},[_vm._v(_vm._s("问题" + (indexs + 1)))]),_c('div',{staticClass:"input-div"},[_c('div',{staticClass:"question-div"},[_c('Input',{staticStyle:{"width":"300px"},attrs:{"type":"textarea","rows":8,"placeholder":"问题"},model:{value:(items.answerName),callback:function ($$v) {_vm.$set(items, "answerName", $$v)},expression:"items.answerName"}})],1),_c('div',{staticClass:"answer-div"},_vm._l((items.optionVoList),function(itemss,indexss){return _c('div',{key:indexss,staticStyle:{"display":"flex","margin-bottom":"10px"}},[_c('Input',{attrs:{"placeholder":"请输入选项"},model:{value:(itemss.optionName),callback:function ($$v) {_vm.$set(itemss, "optionName", $$v)},expression:"itemss.optionName"}}),_c('Checkbox',{staticStyle:{"width":"150px","margin-left":"10px"},attrs:{"true-value":"1","false-value":"0"},on:{"on-change":function (val) {
                                                        _vm.changeCheckbox(val, items.answerType, index, indexs, indexss)
                                                    }},model:{value:(itemss.option),callback:function ($$v) {_vm.$set(itemss, "option", $$v)},expression:"itemss.option"}},[_vm._v(" 答案 ")]),(items.optionVoList.length >= 2)?_c('Button',{staticStyle:{"width":"50px"},attrs:{"icon":"ios-trash","type":"error"},on:{"click":function($event){return _vm.deteleAnswer(index, indexs, indexss)}}}):_vm._e()],1)}),0),_c('div',{staticClass:"add-btn",staticStyle:{"margin-left":"20px"}},[_c('div',{staticStyle:{"width":"100px"}},[_c('Button',{attrs:{"type":"primary"},on:{"click":function($event){return _vm.addAnswer(index, indexs)}}},[_vm._v("添加选项")])],1)]),_c('div',{staticClass:"select-div",staticStyle:{"margin-left":"20px"}},[_c('RadioGroup',{on:{"on-change":function (val) {
                                                    _vm.changeType(val, index, indexs)
                                                }},model:{value:(items.answerType),callback:function ($$v) {_vm.$set(items, "answerType", $$v)},expression:"items.answerType"}},[_c('Radio',{attrs:{"label":"1"}},[_vm._v("单选题")]),_c('Radio',{attrs:{"label":"2"}},[_vm._v("多选题")])],1)],1)])]):_vm._e(),(items.questionType == 2)?_c('FormItem',[_c('span',{staticClass:"validate",attrs:{"slot":"label"},slot:"label"},[_vm._v(_vm._s("问题" + (indexs + 1)))]),_c('div',{staticClass:"input-div"},[_c('div',{staticClass:"question-div"},[_c('Input',{staticStyle:{"width":"200px"},attrs:{"type":"textarea","rows":8,"placeholder":"问题描述"},model:{value:(items.answerName),callback:function ($$v) {_vm.$set(items, "answerName", $$v)},expression:"items.answerName"}})],1),_c('div',{staticClass:"select-div",staticStyle:{"margin-left":"20px"}},[_c('RadioGroup',{model:{value:(items.answerType),callback:function ($$v) {_vm.$set(items, "answerType", $$v)},expression:"items.answerType"}},[_c('Radio',{attrs:{"label":"1"}},[_vm._v("满分5分")]),_c('Radio',{attrs:{"label":"2"}},[_vm._v("满分10分")])],1)],1)])]):_vm._e()],1)],1),_c('div',{staticClass:"right-btn"},[(item.answerVoList.length >= 2)?_c('Button',{attrs:{"icon":"ios-trash","type":"error"},on:{"click":function($event){return _vm.deleteProblem(index, indexs)}}}):_vm._e()],1)])})],2)])])})],2):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }