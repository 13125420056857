<template>
    <div class="content-div" v-if="questionVoList && questionVoList.length">
        <div class="btn-div">
            <Button type="primary" @click="addBtn">添加下一组</Button>
        </div>
        <div class="item" v-for="(item, index) in questionVoList" :key="index">
            <div class="detele-btn" style="display: flex">
                <Button style="margin-left: auto" type="error" icon="ios-trash" v-if="questionVoList.length >= 2" @click="deleteBtn(index)">删除当前组</Button>
            </div>
            <div class="item-div">
                <div class="left">
                    <div class="text">内容设置</div>
                    <div class="form-content">
                        <Form :model="item" label-position="right" :label-width="120" :rules="itemValidateForm" class="form" ref="form">
                            <FormItem label="排序" prop="topicName">
                                <Input type="Number" placeholder="填写排序" v-model.trim="item.seq"></Input>
                            </FormItem>
                            <FormItem>
                                <span slot="label">背景素材:</span>
                                <RadioGroup
                                    v-model="item.extraType"
                                    @on-change="
                                        val => {
                                            changeExtraType(val, index)
                                        }
                                    "
                                >
                                    <Radio label="1">图片</Radio>
                                    <Radio label="2">视频</Radio>
                                </RadioGroup>
                            </FormItem>
                            <!-- 图片 -->
                            <FormItem v-if="item.extraType && item.extraType == '1'">
                                <LiefengUpload
                                    ref="imgData"
                                    v-if="item.extraType == '1' && showImage"
                                    accept=".jpg,.png,.gif,.jpeg"
                                    :format="['jpg', 'png', 'jpeg', 'gif']"
                                    :defaultList="item.extra"
                                    :showView="true"
                                    :target="'image'"
                                    @success="
                                        (target, val) => {
                                            uploadSuccess(target, val, index)
                                        }
                                    "
                                ></LiefengUpload>
                            </FormItem>
                            <!-- 视频 -->
                            <FormItem v-if="item.extraType && item.extraType == '2'">
                                <LiefengUploadVideo
                                    ref="videoData"
                                    v-if="item.extraType == '2'"
                                    :defaultList="item.extra"
                                    :target="'video'"
                                    @success="
                                        (target, val) => {
                                            uploadSuccess(target, val, index)
                                        }
                                    "
                                    :showView="true"
                                ></LiefengUploadVideo>
                            </FormItem>
                            <FormItem prop="topicDesc">
                                <span slot="label" class="validate">内容描述</span>
                                <Input type="textarea" :rows="10" v-model.trim="item.questionDesc" placeholder="内容描述，必填" maxlength="500"></Input>
                            </FormItem>
                            <FormItem prop="topicDesc">
                                <span slot="label">语音文件</span>
                                <LiefengUploadAudio
                                    v-if="showAudio"
                                    :defaultFileList="item.voiceFile"
                                    :ref="`LiefengUploadAudio${index}`"
                                    @success="
                                        val => {
                                            uploadAudio(val, index)
                                        }
                                    "
                                ></LiefengUploadAudio>
                            </FormItem>
                        </Form>
                    </div>
                </div>
                <div class="right">
                    <div class="text">
                        <div>问题设置</div>
                        <div class="right-btn">
                            <Button type="primary" @click="addPromlem(index)">添加多个问题</Button>
                        </div>
                    </div>
                    <div class="form-content" v-for="(items, indexs) in item.answerVoList" :key="indexs">
                        <div class="right-form">
                            <Form label-position="right" :model="items" :label-width="120">
                                <FormItem>
                                    <span slot="label" class="validate">题目类型:</span>
                                    <RadioGroup v-model="items.questionType">
                                        <Radio label="1">选择题</Radio>
                                        <Radio label="2">打分题</Radio>
                                    </RadioGroup>
                                </FormItem>
                                <FormItem v-if="items.questionType == 1">
                                    <span slot="label" class="validate">{{ "问题" + (indexs + 1) }}</span>
                                    <div class="input-div">
                                        <div class="question-div">
                                            <Input type="textarea" :rows="8" style="width: 300px" placeholder="问题" v-model="items.answerName" />
                                        </div>
                                        <div class="answer-div">
                                            <div style="display: flex; margin-bottom: 10px" v-for="(itemss, indexss) in items.optionVoList" :key="indexss">
                                                <Input v-model="itemss.optionName" placeholder="请输入选项" />
                                                <Checkbox
                                                    @on-change="
                                                        val => {
                                                            changeCheckbox(val, items.answerType, index, indexs, indexss)
                                                        }
                                                    "
                                                    true-value="1"
                                                    false-value="0"
                                                    style="width: 150px; margin-left: 10px"
                                                    v-model="itemss.option"
                                                >
                                                    答案
                                                </Checkbox>
                                                <Button style="width: 50px" v-if="items.optionVoList.length >= 2" icon="ios-trash" type="error" @click="deteleAnswer(index, indexs, indexss)"></Button>
                                            </div>
                                        </div>
                                        <div class="add-btn" style="margin-left: 20px">
                                            <div style="width: 100px">
                                                <Button type="primary" @click="addAnswer(index, indexs)">添加选项</Button>
                                            </div>
                                        </div>
                                        <div class="select-div" style="margin-left: 20px">
                                            <RadioGroup
                                                v-model="items.answerType"
                                                @on-change="
                                                    val => {
                                                        changeType(val, index, indexs)
                                                    }
                                                "
                                            >
                                                <Radio label="1">单选题</Radio>
                                                <Radio label="2">多选题</Radio>
                                            </RadioGroup>
                                        </div>
                                    </div>
                                </FormItem>
                                <FormItem v-if="items.questionType == 2">
                                    <span slot="label" class="validate">{{ "问题" + (indexs + 1) }}</span>
                                    <div class="input-div">
                                        <div class="question-div">
                                            <Input type="textarea" :rows="8" style="width: 200px" placeholder="问题描述" v-model="items.answerName" />
                                        </div>
                                        <div class="select-div" style="margin-left: 20px">
                                            <RadioGroup v-model="items.answerType">
                                                <Radio label="1">满分5分</Radio>
                                                <Radio label="2">满分10分</Radio>
                                            </RadioGroup>
                                        </div>
                                    </div>
                                </FormItem>
                            </Form>
                        </div>
                        <div class="right-btn">
                            <Button icon="ios-trash" v-if="item.answerVoList.length >= 2" type="error" @click="deleteProblem(index, indexs)"></Button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import LiefengUpload from "@/components/LiefengUpload"
import LiefengUploadVideo from "@/components/LiefengUploadVideo"
import LiefengUploadAudio from "@/components/LiefengUploadAudio"
export default {
    components: { LiefengUpload, LiefengUploadVideo, LiefengUploadAudio },
    props: {
        questionList: {
            type: Array,
            default: () => {
                return []
            },
        },
    },
    data() {
        return {
            questionVoList: [
                {
                    questionName: "", //答题名称
                    questionDesc: "", //答题备注
                    seq: "", //排序
                    extraType: "1", //附件类型
                    extra: null, //附件内容
                    voiceFile: null,
                    answerVoList: [
                        {
                            answerName: "", //题目名称
                            questionType: "1", //答题类型
                            answerType: "1", //题目类型
                            seq: "", //排序
                            optionVoList: [
                                {
                                    optionName: "", //选项名称
                                    option: "0", //是否答案
                                    seq: "", //排序
                                },
                            ],
                        },
                    ],
                },
            ],
            addIndex: -1,
            indexList: [],

            showImage: true,

            showAudio: true,
        }
    },
    methods: {
        uploadAudio(val, index) {
            if (val && val.length) {
                this.questionVoList[index].voiceFile = val
            } else {
                this.questionVoList[index].voiceFile = []
            }
        },
        changeExtraType(val, index) {
            this.questionVoList[index].extra = []
        },
        // 修改是否答案框
        changeCheckbox(val, type, index, indexs, indexss) {
            // 如果是单选
            if (type == 1 && val == "1") {
                this.questionVoList[index].answerVoList[indexs].optionVoList.map(item => {
                    item.option = "0"
                })
                this.questionVoList[index].answerVoList[indexs].optionVoList[indexss].option = "1"
            }
        },
        // 修改单选多选
        changeType(val, index, indexs) {
            this.questionVoList[index].answerVoList[indexs].optionVoList.map(item => {
                item.option = "0"
            })
        },
        // 删除当前组
        deleteBtn(index) {
            this.$nextTick(() => {
                this.questionVoList.splice(index, 1).map(item => {
                    return {
                        ...item,
                        extra: !item.extra || !item.extra.length ? null : item.extra,
                        voiceFile: !item.voiceFile || !item.voiceFile.length ? null : item.voiceFile,
                    }
                })
            })
        },
        // 点击添加下次组按钮
        addBtn() {
            this.questionVoList.unshift({
                questionName: "", //答题名称
                questionDesc: "", //答题备注
                seq: "", //排序
                extraType: "1", //附件类型
                extra: null, //附件内容
                voiceFile: null,
                answerVoList: [
                    {
                        answerName: "", //题目名称
                        questionType: "1", //答题类型
                        answerType: "1", //题目类型
                        seq: "", //排序
                        optionVoList: [
                            {
                                optionName: "", //选项名称
                                option: "0", //是否答案
                                seq: "", //排序
                            },
                        ],
                    },
                ],
            })
            this.showImage = false
            this.showAudio = false
            this.$nextTick(() => {
                this.showImage = true
                this.showAudio = true
            })
        },
        // 点击添加问题按钮
        addPromlem(index) {
            this.questionVoList[index].answerVoList.push({
                answerName: "", //题目名称
                questionType: "1", //答题类型
                answerType: "1", //题目类型
                seq: "", //排序
                optionVoList: [
                    {
                        optionName: "", //选项名称
                        option: "0", //是否答案
                        seq: "", //排序
                    },
                ],
            })
        },
        deleteProblem(index, indexs) {
            this.questionVoList[index].answerVoList.splice(indexs, 1)
        },
        // 点击添加选项按钮
        addAnswer(index, indexs) {
            this.questionVoList[index].answerVoList[indexs].optionVoList.push({
                optionName: "", //选项名称
                option: "0", //是否答案
                seq: "", //排序
            })
        },
        deteleAnswer(index, indexs, indexss) {
            this.questionVoList[index].answerVoList[indexs].optionVoList.splice(indexss, 1)
        },
        uploadSuccess(val, file, index) {
            if (file && file.url) this.questionVoList[index].extra = [{ name: "", url: file.url }]
            else this.questionVoList[index].extra = []
        },
        // 验证提交
        sumbit() {
            if (this.questionVoList && this.questionVoList.length) {
                try {
                    this.questionVoList.forEach((item, index) => {
                        if (!item.questionDesc || item.questionDesc == "") {
                            this.$Message.warning({
                                content: `第${index + 1}组内容描述不能为空`,
                                background: true,
                            })
                            return new Error("条件不符合")
                        }
                        item.answerVoList.forEach((items, indexs) => {
                            if (!items.answerName || items.answerName == "") {
                                this.$Message.warning({
                                    content: `第${index + 1}组问题${indexs + 1}名称不能为空`,
                                    background: true,
                                })
                                return new Error("条件不符合")
                            } else {
                                if (items.questionType == 1) {
                                    items.optionVoList.forEach((itemss, indexss) => {
                                        if (!itemss.optionName || itemss.optionName == "") {
                                            this.$Message.warning({
                                                content: `第${index + 1}组问题${indexs + 1}选项${indexss + 1}不能为空`,
                                                background: true,
                                            })
                                            return new Error("条件不符合")
                                        } else {
                                            if (indexss == items.optionVoList.length - 1) {
                                                let list = []
                                                items.optionVoList.map(itemsss => list.push(itemsss.option))
                                                if (list.join(",").indexOf("1") == -1) {
                                                    this.$Message.warning({
                                                        content: `第${index + 1}组问题${indexs + 1}请勾选答案`,
                                                        background: true,
                                                    })
                                                    return new Error("条件不符合")
                                                }
                                                if (index == this.questionVoList.length - 1 && indexs == item.answerVoList.length - 1) this.$emit("success", this.questionVoList)
                                            }
                                        }
                                    })
                                } else {
                                    if (index == this.questionVoList.length - 1 && indexs == item.answerVoList.length - 1) this.$emit("success", this.questionVoList)
                                }
                            }
                        })
                    })
                } catch (e) {
                    console.log("输出错误", e)
                }
            }
        },
    },
    watch: {
        questionList: {
            deep: true,
            immediate: true,
            handler(newVal, oldVal) {
                if (newVal == null || !newVal.length) return
                this.questionVoList = newVal.map((item, index) => {
                    return {
                        ...item,
                        extra: item.extra && item.extra != "" ? [{ name: "", url: item.extra }] : [],
                        voiceFile: item.voiceFile && item.voiceFile != '' ? [{ name: item.voiceFile.split("/")[item.voiceFile.split("/").length - 1], url: item.voiceFile }] : [],
                    }
                })
            },
        },
    },
}
</script>

<style lang="less" scoped>
.content-div {
    .btn-div {
        margin: 0px 0 0 20px;
    }
    .item {
        border-bottom: 1px solid #ccc;
        margin: 20px 0;
        &:nth-last-child(1) {
            border-bottom: none;
        }
    }
    .item-div {
        display: flex;
        padding: 20px;
        .left {
            width: 30%;
            border-right: 1px solid #ccc;
            padding-right: 20px;
        }
        .right {
            flex: 1;
            padding-left: 20px;
            .form-content {
                margin-top: 20px;
                border-bottom: 1px solid rgba(#ccc, 0.5);
                display: flex;
                &:nth-last-child(1) {
                    border-bottom: none;
                }
                .right-form {
                    flex: 1;
                }
                .right-btn {
                    margin-left: auto;
                }
            }
        }
    }
    .text {
        font-size: 16px;
        font-weight: bold;
        line-height: 40px;
        color: #287aad;
        display: flex;
        .right-btn {
            margin-left: auto;
        }
    }
    .input-div {
        display: flex;
    }
    .answer-div {
        margin-left: 20px;
    }
    .validate {
        &::before {
            content: "*";
            display: inline-block;
            margin-right: 4px;
            line-height: 1;
            font-family: SimSun;
            font-size: 14px;
            color: #ed4014;
        }
    }
}
</style>